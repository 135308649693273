.App {
  width: 100%;
  height: 100%;
  position: relative;
}
nav {
  width: 100%;
  height: 4rem;
  font-size: 1.25rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  background-color:whitesmoke;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.26);
  position: relative;
  z-index: 5;
}
main {
  width: 100%;
  height: 85vh;
  overflow-y: auto;
}
footer {
  width: 100%;
  height: 2rem;
  font-weight: bold;
  text-align: center;
  display: grid;
  place-items: center;
  background: #0e0e0e;
  color: white;
  position: fixed;
  bottom: 0%;
  z-index: 5;
}