.about-container {
    width: 100%;
    height: 85vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}
.about_me-container {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.about_me_title {
    font-size: 1.1rem;
    margin-top: 0.25rem;
}
.about_me-container img {
    width: 32px;
}
.about_me-container img:hover {
    scale: 110%;
}
.about_me_content_skills {
    list-style: none;
}
.about_me_content_skills li::before {
    content: '✓ ';
}
.about_me_current_description {
    text-align: center;
}
.about_me_current_description img {
    display: inline-block;
    vertical-align: middle;
}
.about_me_github {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.contact_me-container {
    display: flex;
    flex-direction: column;
    margin: 0% 5%;
}
.contact_me_title {
    margin-top: 0%;
}
.contact_me_contacts-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    column-gap: 3rem;
}
.contact_me_contact-container {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
}
@media screen and (max-width: 385px) {
    .about_me_title {
        font-size: 0.95rem;
    }
    .about_me_github p {
        margin: 0.25rem 0rem;
    }
    .about_me_current-container{
        display: none;
    }
}